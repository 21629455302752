import { apiUtil } from "../../core/utils";
import { JobDataAIAnalyzed } from "../types/api";

export const analyzeJobDataByAI = async (jobPostId: number | string, params: { files: File[], url?: string; }) => {
  const { files, url } = params;
  const data = new FormData();

  files.forEach((file, i) => {
    data.append('files[]', file);
  });

  if (url)
    data.append('url', url);

  const response = await apiUtil.patch(`/job_posts/${jobPostId}/ai_analyze`, data);
  if (response.status >= 200 && response.status < 300) {
      return response.data as JobDataAIAnalyzed;
  }

  throw new Error('API error');
};

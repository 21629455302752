import { FunctionComponent } from "react"
import { useTranslation } from "react-i18next";
import { FormHeader, FormHeaderContainer, FormHeaderTextInJapanese, FormHeaderTextInTransLang } from "../../commonStyles"
import { LanguageCode } from "../../../../../core/enums/language";

type ContractMainHeaderProps = {
  languageCode: LanguageCode
}

const ContractMainHeader: FunctionComponent<ContractMainHeaderProps> = ({ languageCode }) => {
  const { i18n } = useTranslation();
  const tFixedWithEmpCond = i18n.getFixedT(languageCode, "", "proposal.employment_conditions");
  const tFixedEmpCond = (key: string): string => tFixedWithEmpCond(key).toString();

  return (
      <FormHeaderContainer>
        <div>
          <span>参考様式１−６号</span>
          <br />
          <span>{tFixedEmpCond("ref_format_no_1_6")}</span>
        </div>
        <FormHeader>
          <FormHeaderTextInJapanese>
            <span>雇</span>
            <span>用</span>
            <span>条</span>
            <span>件</span>
            <span>書</span>
          </FormHeaderTextInJapanese>
          <FormHeaderTextInTransLang>
            {tFixedEmpCond("main_header")}
          </FormHeaderTextInTransLang>
        </FormHeader>
    </FormHeaderContainer>
  )
}

export default ContractMainHeader;

import { FunctionComponent, useEffect, useState } from "react";
import {
  Column5,
  Item20,
  Segment,
  FlexRowColumn,
} from "../../commonStyles";
import CheckMark from "../../components/CheckMark";
import DescriptionField from "../../components/DescriptionField";
import {
  Section,
  Indent,
  ListBlockContent,
  SectionHeadingContainer,
} from "./styles";
import { EcContractType } from "../../../../../core/enums/employmentConditions";
import { TemplateSectionProps } from "../../types";
import { useTranslation } from "react-i18next";

interface PlaceOfEmploymentSectionProps extends TemplateSectionProps {
  showAddress?: boolean
}

const PlaceOfEmploymentSection: FunctionComponent<PlaceOfEmploymentSectionProps> = ({
  employmentCondition: ec,
  ecTranslationHandler: transHandler,
  showAddress = true,
  i18nFormPrefixKey,
}) => {
  const { i18n } = useTranslation();
  const transLang = transHandler.targetLangCode;
  const tFixedToTransLang = i18n.getFixedT(transLang, "", i18nFormPrefixKey);
  const tFixed = (key: string): string => tFixedToTransLang(key).toString();
  const [contractType, setContractType] = useState<string>("");
  const [officeNameInJp, setOfficeNameInJp] = useState<string>("");
  const [officeNameInEn, setOfficeNameInEn] = useState<string>("");
  const [officeAddress, setOfficeAddress] = useState<string>("");
  const [officeAddressTrans, setOfficeAddressTrans] = useState<string>("");
  const [officePhoneNumber, setOfficePhoneNumber] = useState<string>("");
  const [jobChangeAfterEmployment, setJobChangeAfterEmployment] = useState<boolean>();
  const [changedOfficeNameInJp, setChangedOfficeNameInJp] = useState<string>("");
  const [changedOfficeNameInEn, setChangedOfficeNameInEn] = useState<string>("");
  const [changedOfficeAddress, setChangedOfficeAddress] = useState<string>("");
  const [changedOfficeAddressTrans, setChangedOfficeAddressTrans] = useState<string>("");
  const [changedOfficePhoneNumber, setChangedOfficePhoneNumber] = useState<string>("");


  useEffect(() => {
    if (!ec)
      return;

    setContractType(ec.placeOfEmploymentContractType);
    setOfficeNameInJp(ec.placeOfEmploymentJaOfficeName);
    setOfficeNameInEn(ec.placeOfEmploymentEnOfficeName);
    setOfficeAddress([
      ec.placeOfEmploymentJaPrefecture,
      ec.placeOfEmploymentJaCityWard,
      ec.placeOfEmploymentJaTown,
      ec.placeOfEmploymentJaAddressNumber,
      ec.placeOfEmploymentJaBuilding
    ].filter(v => v).join(" "));
    setOfficeAddressTrans([
      ec.placeOfEmploymentEnPrefecture,
      ec.placeOfEmploymentEnCityWard,
      ec.placeOfEmploymentEnTown,
      ec.placeOfEmploymentEnAddressNumber,
      ec.placeOfEmploymentEnBuilding
    ].filter(v => v).join(" "));
    setOfficePhoneNumber(ec.placeOfEmploymentPhoneNumber);

    setJobChangeAfterEmployment(ec.placeOfEmploymentChangesAfterEmployment);

    if (ec.placeOfEmploymentChangesAfterEmployment === true) {
      setChangedOfficeNameInJp(ec.placeOfEmploymentJaOfficeNameAfterChange);
      setChangedOfficeNameInEn(ec.placeOfEmploymentEnOfficeNameAfterChange);
      setChangedOfficeAddress([
        ec.placeOfEmploymentJaPrefectureAfterChange,
        ec.placeOfEmploymentJaCityWardAfterChange,
        ec.placeOfEmploymentJaTownAfterChange,
        ec.placeOfEmploymentJaAddressNumberAfterChange,
        ec.placeOfEmploymentJaBuildingAfterChange
      ].filter(v => v).join(" "));
      setChangedOfficeAddressTrans([
        ec.placeOfEmploymentEnPrefectureAfterChange,
        ec.placeOfEmploymentEnCityWardAfterChange,
        ec.placeOfEmploymentEnTownAfterChange,
        ec.placeOfEmploymentEnAddressNumberAfterChange,
        ec.placeOfEmploymentEnBuildingAfterChange
      ].filter(v => v).join(" "));
      setChangedOfficePhoneNumber(ec.placeOfEmploymentPhoneNumberAfterChange);
    }
  }, [ec])

  return (
    <Section>
      <SectionHeadingContainer>
        <span>II.</span>
        <Segment>
          <span>就業の場所</span>
          <br />
          <span>{tFixed("work_location")}</span>
        </Segment>
      </SectionHeadingContainer>
      <Indent>
        <ListBlockContent>
          <Segment>
            <FlexRowColumn>
              <Item20>
                <CheckMark
                  checked={contractType === EcContractType.DirectEmployment}
                >
                  直接雇用（以下に記入）
                </CheckMark>
              </Item20>
              <Item20>
                <CheckMark
                  checked={contractType === EcContractType.DispatchEmployment}
                >
                  派遣雇用（別紙「就業条件明示書」に記入）
                </CheckMark>
              </Item20>
            </FlexRowColumn>
            <FlexRowColumn>
              <Item20>
                <CheckMark
                  checked={contractType === EcContractType.DirectEmployment}
                >
                  {tFixed("direct_employment")}
                </CheckMark>
              </Item20>
              <Item20>
                <CheckMark
                  checked={contractType === EcContractType.DispatchEmployment}
                >
                  {tFixed("dispatch_employment")}
                </CheckMark>
              </Item20>
            </FlexRowColumn>
          </Segment>

          {showAddress && (
            <>
              <Segment>
                <Column5>
                  <DescriptionField
                    label={"事業所"}
                    value={officeNameInJp}
                  />
                  <DescriptionField
                    label={tFixed("business_office_name")}
                    value={officeNameInEn}
                  />
                </Column5>
              </Segment>
              <Segment>
                <Column5>
                  <DescriptionField
                    label={"所在地"}
                    value={officeAddress}
                  />
                  <DescriptionField
                    label={tFixed("office_location")}
                    value={officeAddressTrans}
                  />
                </Column5>
              </Segment>
              <Segment>
                <Column5>
                  <DescriptionField
                    label={"連絡先"}
                    value={officePhoneNumber}
                  />
                  <DescriptionField
                    label={tFixed("contact_info")}
                    value={officePhoneNumber}
                  />
                </Column5>
              </Segment>
            </>
          )}

          <Segment
            style={{ marginTop: 20 }}
          >
            <span>（雇入れ直後）</span>
            <br />
            <span>{tFixed("immediately_after_hiring")}</span>
            <br />
            <CheckMark
              checked={jobChangeAfterEmployment === false}
            >
              変更の可能性なし（変更ある場合は以下に記入）
            </CheckMark>
            <br />
            <CheckMark
              checked={jobChangeAfterEmployment === false}
            >
              {tFixed("no_change_possible")}
            </CheckMark>
          </Segment>

          {showAddress && (
            <>
              <Segment>
                <span>（変更の範囲）</span>
                <br />
                <span>{tFixed("scope_of_changes")}</span>
              </Segment>
              <Segment>
                <Column5>
                  <DescriptionField
                    label={"事業所"}
                    value={changedOfficeNameInJp}
                  />
                  <DescriptionField
                    label={tFixed("business_office_name")}
                    value={changedOfficeNameInEn}
                  />
                </Column5>
              </Segment>
              <Segment>
                <Column5>
                  <DescriptionField
                    label={"所在地"}
                    value={changedOfficeAddress}
                  />
                  <DescriptionField
                    label={tFixed("office_location")}
                    value={changedOfficeAddressTrans}
                  />
                </Column5>
              </Segment>
              <Segment>
                <Column5>
                  <DescriptionField
                    label={"連絡先"}
                    value={changedOfficePhoneNumber}
                  />
                  <DescriptionField
                    label={tFixed("contact_info")}
                    value={changedOfficePhoneNumber}
                  />
                </Column5>
              </Segment>
            </>
          )}
        </ListBlockContent>
      </Indent>
    </Section>
  );
};

export default PlaceOfEmploymentSection;

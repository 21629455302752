import { FunctionComponent, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import LoadingPrompt from "../../../proposal/pages/EmploymentConditionsFormPage/components/LoadingPrompt";
import { validateEmploymentConditions, getJob } from "../../apis/job";
import { Job } from "../../types/api";
import { EmploymentCondition } from "../../../proposal/types";
import { useParams } from "react-router-dom";
import { LanguageCode } from "../../../core/enums/language";
import { ReactComponent as BackIcon } from "../../../assets/icon-back.svg";
import Page from "../../../core/components/Page";
import styled from "styled-components";
import { Column20 } from "../../../proposal/components/EmploymentConditionsTemplate/commonStyles";
import { EmploymentConditionTranslationHandler } from "../../../proposal/components/EmploymentConditionsTemplate/util";
import WagePaymentHeader from "../../../proposal/components/EmploymentConditionsTemplate/blocks/headers/WagePaymentHeader";
import {
  CompensationSection,
  ContractConclusionSection,
  ContractPeriodSection,
  EmployeeEmployerInfoSection,
  HolidaysSection,
  OtherSection,
  PlaceOfEmploymentSection,
  ResignationSection,
  TypeOfWorkSection,
  VacationSection,
  WorkHoursSection,
} from "../../../proposal/components/EmploymentConditionsTemplate/blocks/employmentConditions";
import {
  BaseWageSection,
  BenefitsSection,
  DeductionsSection,
  EstimatedPaymentSection,
  TakeHomeWageSection,
} from "../../../proposal/components/EmploymentConditionsTemplate/blocks/compensations";
import { useTranslation } from "react-i18next";
import { useReducerContext } from "../../../core/contexts/ReducerContext";
import { ContractMainHeader } from "../../../proposal/components/EmploymentConditionsTemplate/blocks/headers";
import ACTIONS from "../../../core/constants/actions";

interface EmploymentConditionPageProps {}

const SplitStack = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const ECH2 = styled.h2`
  font-weight: 500;
  font-size: 26px;
  line-height: 1.6;
`

const PDFContainer = styled.div`
  width: 100%;
  background-color: white;
  padding: 60px;
  border-radius: 5px;
  border: 0.5px solid #e5d5d5;
  font-size: 12px;
  line-height: normal;
  font-family: "Hiragino mincho pro", "Zen old mincho", serif;

  @media print {
    visibility: visible;
    z-index: 1000;
    border: none;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: 0;
    padding: 0;
  }
`;

const EEInfoWrap = styled.div`
`

const CompensationsSections = styled(Column20)`
  @media print {
    page-break-after: always;
  }
`;

const Spacer = styled.div`
  margin-bottom: 40px;
`

const Separator = styled.hr`
  margin-top: 60px;
  margin-bottom: 60px;
  @media print {
    visibility: hidden;
  }
`;

const EmploymentConditionPage: FunctionComponent<
  EmploymentConditionPageProps
  > = () => {
  const navigate = useNavigate();
  const { state, dispatch } = useReducerContext();
  const { isEmployer, company } = state;
  const { t, i18n } = useTranslation();
  const { jobId } = useParams<{ jobId: string }>();
  const [job, setJob] = useState<Job | null>(null);

  const handleValidate = async () => {
    dispatch({ type: ACTIONS.START_LOADING })
    try {
      const valid = await validateEmploymentConditions(job!.id)
      if (valid) {
        dispatch({
          type: ACTIONS.SET_PROMPT,
          payload: {
            type: 'success',
            message: t('core.employment_conditions.snackbar_validation_success')
          }
        });
        return
      }
      throw new Error() // unreachable code
    } catch (error: any) {
      let errorMessage = t('core.unexpected')

      if (error.response?.status === 403) {
        errorMessage = t('core.unauthorized')
      }

      if (error.response?.status === 422) {
        const missingItems = error.response?.data?.missings
        const translated = missingItems.map((item: string) => {
          const key = item.startsWith("ec_") ? item.slice(3) : item;
          return t(`core.employment_conditions.${key}`);
        })
        errorMessage = `
          ${t('core.employment_conditions.snackbar_validation_failure')}
          <br />
          <br />
          ※ ${translated.join(", ")}
        `
      }

      dispatch({
        type: ACTIONS.SET_PROMPT,
        payload: {
          type: 'warning',
          message: errorMessage
        }
      });
    } finally {
      dispatch({ type: ACTIONS.STOP_LOADING })
    }
  }

  const showDetails = useMemo(() => {
    if (isEmployer && job?.employerId === company?.employer?.id) {
      return true;
    }

    return false;
  }, [job, company])

  const langCode = useMemo(() => {
    if (i18n.language === LanguageCode.Japanese) {
      return LanguageCode.English;
    }

    return i18n.language as LanguageCode;
  }, [i18n.language])

  const employmentCondition: EmploymentCondition | null = useMemo(() => {
    if (job === null) {
      return null;
    }

    const preEC = Object.keys(job).reduce((acc: any, key: string) => {
      if (key.startsWith("ec")) {
        const convertedKey = key.charAt(2).toLowerCase() + key.slice(3);
        acc[convertedKey as keyof EmploymentCondition] =
          job[key as keyof Job];
      } else {
        acc[key] = job[key as keyof Job];
      }
      return acc;
    }, {} as EmploymentCondition);

    return preEC;
  }, [job]);

  const { ecProps, compProps } = useMemo(() => {
    if (employmentCondition === null) {
      return { ecProps: null, compProps: null };
    }

    const transHandler = new EmploymentConditionTranslationHandler(employmentCondition, langCode);
    const ecProps = {
      employmentCondition,
      ecTranslationHandler: transHandler,
      i18nFormPrefixKey: "proposal.employment_conditions",
      company: null
    }
    const compProps = {
      employmentCondition,
      ecTranslationHandler: transHandler,
      i18nFormPrefixKey: "proposal.compensations"
    }

    return { ecProps, compProps }
  }, [employmentCondition, langCode])

  useEffect(() => {
    const initJob = async () => {
      if (jobId) {
        const job = await getJob(jobId);
        setJob(job);
      }
    };
    initJob();
  }, [jobId]);

  useEffect(() => {
    if (showDetails) {
      handleValidate()
    }

  }, [showDetails])

  if (ecProps === null || compProps === null) {
    return <LoadingPrompt />;
  }

  return (
    <Page>
      {isEmployer && (
        <BackIcon onClick={() => navigate(-1)} style={{ cursor: "pointer" }} />
      )}
      <SplitStack>
        <ECH2>{t("job.employment_conditions_sample")}</ECH2>
      </SplitStack>
      <PDFContainer>
        {showDetails && (
          <>
            <ContractMainHeader languageCode={langCode} />
            <EEInfoWrap>
              <EmployeeEmployerInfoSection isJobPost={true} {...ecProps} />
            </EEInfoWrap>
          </>
        )}
        <ContractPeriodSection
          {...ecProps}
          showECPeriod={showDetails}
          showRenewalLimit={showDetails}
        />
        <PlaceOfEmploymentSection
          {...ecProps}
          showAddress={showDetails}
        />
        <TypeOfWorkSection {...ecProps} />
        <WorkHoursSection {...ecProps} />
        <HolidaysSection {...ecProps} />
        <VacationSection {...ecProps} />
        <CompensationSection {...ecProps} />
        <ResignationSection {...ecProps} />
        <OtherSection {...ecProps} />
        <Spacer />
        <ContractConclusionSection {...ecProps} isJobPost={true} hasSignature={showDetails} />
        <Separator />
        <WagePaymentHeader languageCode={langCode} />
        <CompensationsSections>
          <BaseWageSection {...compProps}/>
          <BenefitsSection {...compProps}/>
          <EstimatedPaymentSection {...compProps}/>
          <DeductionsSection {...compProps}/>
          <TakeHomeWageSection {...compProps}/>
        </CompensationsSections>
      </PDFContainer>
    </Page>
  );
};

export default EmploymentConditionPage;

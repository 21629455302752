import { Industry, Job, JobAnswer, JobQuestion, JobImage, JobExtended } from '../types/api';
import { apiUtil } from '../../core/utils';
import { PREFECTURES_EN } from '../../core/constants/prefectures';
import { JobState } from '../enums/job';

export const getIndustries = async () => {
    const response = await apiUtil.get('/industries');
    if (response.status >= 200 && response.status < 300) {
        return response.data as Industry[];
    }
    return [];
};

export const createJob = async (job?: Partial<JobExtended>) => {
    const response = await apiUtil.post(
        '/job_posts',
        {
            ...job,
        },
    );
    if (response.status >= 200 && response.status < 300) {
        return response.data as Job;
    }
    throw new Error('API error');
};

export const createCopiedJob = async (jobId: number|string) => {
    const response = await apiUtil.post(
        `/job_posts/${jobId}/copy`,
    );
    if (response.status >= 200 && response.status < 300) {
        return response.data as Job;
    }
    throw new Error('API error');
}

export const getJob = async (jobId: number|string) => {
    const response = await apiUtil.get(`/job_posts/${jobId}`);
    if (response.status >= 200 && response.status < 300) {
        return response.data as Job;
    }
    throw new Error('API error');
};

export const deleteJob = async (jobId: number|string) => {
    const response = await apiUtil.delete(`/job_posts/${jobId}`);
    if (response.status >= 200 && response.status < 300) {
        return response.data;
    }
    throw new Error('API error');
}

export const getJobs = async (params: {} = {}) => {
    const response = await apiUtil.get('/job_posts', { params });
    if (response.status >= 200 && response.status < 300) {
        return response.data as Job[];
    }
    throw new Error('API error');
};

export const getJobsCount = async (params: {} = {}) => {
    const response = await apiUtil.get('/job_posts/count', { params });
    if (response.status >= 200 && response.status < 300) {
        return response.data as number;
    }
    throw new Error('API error');
}

export const getPrefecturesWithAvailableJob = async () => {
    const response = await apiUtil.get('/postal_code_addresses/prefectures_with_available_job');
    if (response.status >= 200 && response.status < 300) {

        //Sort the order (from north to south)
        const prefectures: string [] = response.data.sort((a: string,b: string) => {
            return PREFECTURES_EN.indexOf(a) - PREFECTURES_EN.indexOf(b);
        })
        return prefectures;
    }
    throw new Error('API error');
}

export const updateJob = async (jobId: number|string, job: Partial<JobExtended>) => {
    const processed: Record<string, any> = { ...job };
    Object.keys(job).forEach((key) => {
        if (processed[key] === undefined) {
            processed[key] = null;
        }
    });

    const response = await apiUtil.patch(
        `/job_posts/${jobId}`,
        {
            ...processed,
        },
    );
    if (response.status >= 200 && response.status < 300) {
        return response.data;
    }
    throw new Error('API error');
};

export const archiveJob = async (jobId: number|string) => {
    const response = await apiUtil.patch(
        `/job_posts/${jobId}`,
        {
            state: JobState.Archived,
        },
    );
    if (response.status >= 200 && response.status < 300) {
        return response.data;
    }
    throw new Error('API error');
};

export const createJobImage = async (jobId: number|string, file: File) => {
    const data = new FormData();
    data.append('jobPostId', String(jobId));
    data.append('image', file);
    const response = await apiUtil.post('/job_post_images', data);
    if (response.status >= 200 && response.status < 300) {
        return response.data as JobImage;
    }
    throw new Error('API error');
};

export const validateEmploymentConditions = async (jobId: number|string) => {
    const response = await apiUtil.get(`/job_posts/${jobId}/validate_employment_conditions`);

    return response
}

export const deleteJobImage = async (imageId: number|string) => {
    const response = await apiUtil.delete(`/job_post_images/${imageId}`);
    if (response.status >= 200 && response.status < 300) {
        return response.data;
    }
    throw new Error('API error');
};

export const createJobQuestion = async (jobQuestion: { [key: string]: any }) => {
    const response = await apiUtil.post(
        '/job_post_questions',
        {
            ...jobQuestion,
        },
    );
    if (response.status >= 200 && response.status < 300) {
        return response.data as JobQuestion;
    }
    throw new Error('API error');
};

export const updateJobQuestion = async (jobQuestionId: number|string, params: { [key: string]: any }) => {
    const response = await apiUtil.patch(`/job_post_questions/${jobQuestionId}`, params);
    if (response.status >= 200 && response.status < 300) {
        return response.data as JobQuestion;
    }
    throw new Error('API error');
};

export const getJobQuestions = async (jobId: number|string) => {
    const params = { jobPostId: jobId };
    const response = await apiUtil.get('/job_post_questions', { params });
    if (response.status >= 200 && response.status < 300) {
        return response.data as JobQuestion[];
    }
    throw new Error('API error');
};

export const createJobAnswer = async (jobAnswer: { [key: string]: any }) => {
    const response = await apiUtil.post(
        '/job_post_answers',
        {
            ...jobAnswer,
        },
    );
    if (response.status >= 200 && response.status < 300) {
        return response.data as JobAnswer;
    }
    throw new Error('API error');
};

export const updateJobAnswer = async (jobAnswerId: number|string, params: { [key: string]: any }) => {
    const response = await apiUtil.patch(`/job_post_answers/${jobAnswerId}`, params);
    if (response.status >= 200 && response.status < 300) {
        return response.data as JobAnswer;
    }
    throw new Error('API error');
};

import { FunctionComponent, useState } from "react";
import {
  FieldCell,
  LabelCell,
  GridRowsContainer,
  GridRow,
  LocalTextInputExtend,
  Column10,
  FullRowCell,
} from "../commonStyle";
import Label from "../../../../core/components/Label";
import Text from "../../../../core/components/Text";
import styled from "styled-components";
import { JobFormSectionProps } from "../type";
import { useReducerContext } from "../../../../core/contexts/ReducerContext";
import Button from "../../../../core/components/Button";
import UploadButton from "../../../../core/components/UploadButton";
import ACTIONS from "../../../../core/constants/actions";
import Row from "../../../../core/components/Row";
import { analyzeJobDataByAI } from "../../../apis/analysis";
import { JobDataAIAnalysis422Error, JobDataAIAnalyzed, JobExtended } from "../../../types/api";
import {
  JobCommuteMethodFromAI,
  JobContractPeriodFromAI,
  JobContractRenewalFromAI,
  JobDormTypeFromAI,
  JobInterviewTypeFromAI,
  JobJlptLevelFromAI,
  JobJaConversationSkillFromAI,
  JobPreferredGenderFromAI,
  JobSalaryTypeFromAI,
  JobWorkStyleFromAI,
} from "../../../enums/ai";
import {
  JobCommuteMethod,
  JobContractPeriod,
  JobContractRenewal,
  JobDormType,
  JobInterviewType,
  JobJlptLevel,
  JobJPConversationSkill,
  JobPreferredGender,
  JobSalaryType,
  JobWorkStyle,
} from "../../../enums/job";
import { EcWagePaymentType } from "../../../../core/enums/employmentConditions";
import { ReactComponent as TrashIcon } from "../../../../assets/icon-trash-can.svg";
import { getJob, updateJob } from "../../../apis/job";
import { ReactComponent as AlertIcon } from "../../../../assets/icon-alert.svg";
import { isEmpty, isValidDateString } from "../../../../core/utils";
import axios from "axios";
import { PREFECTURES_EN, PREFECTURES_JA } from "../../../../core/constants/prefectures";

interface AiAnalysisSectionProps extends JobFormSectionProps {
  analysisAttemptLimit: number;
}

const LocalFullRowCell = styled(FullRowCell)`
  display: flex;
  justify-content: space-between;
  padding-right: 30px;
  gap: 40px;
`;

const Stack = styled.div`
  display: flex;
`

const LocalRow = styled(Row)`
  align-items: center;
  gap: 10px;
`;

const NoteList = styled.ul`
  list-style: none;
  padding-left: 0;
  margin: 0;
`;

const NoteListItem = styled.li`
  position: relative;
  padding-left: 16px;

  &::before {
    content: "•";
    color: #8a8e94;
    font-size: 15px;
    position: absolute;
    left: 0;
    top: 0;
  }
`;

const Note = styled(Text)`
  font-size: 12px;
  color: #8a8e94;
`;

const DeleteIcon = styled(TrashIcon)`
  cursor: pointer;
`;

const AlertBanner = styled.div`
  padding: 10px 20px;
  border-radius: 5px;
  background-color: #fff0f0;
  display: flex;
  gap: 20px;
  align-items: center;
  flex-grow: 1;

  & * {
    color: #e93232;
    fill: #e93232;
  }
`;

const LocalUploadButton = styled(UploadButton)`
  width: 200px;
`;

export const AiAnalysisSection: FunctionComponent<AiAnalysisSectionProps> = ({
  job,
  analysisAttemptLimit,
  onChange,
}) => {
  const FILE_SIZE_LIMIT = 5 * 1024 * 1024; // 5MB
  const { dispatch } = useReducerContext();
  const [urlToAnalyze, setUrlToAnalyze] = useState<string>("");
  const [filesToAnalyze, setFilesToAnalyze] = useState<File[]>([]);
  const numOfFiles = 5;
  const disableUrlInput = filesToAnalyze.length > 0;
  const disableFileUpload = !!urlToAnalyze;
  const isReadyToAnalyze = !!urlToAnalyze || filesToAnalyze.length > 0;
  const succeededAtLeastOnce = (job?.aiAnalyzeSuccessCount ?? 0) > 0;

  const onFileChange = (file: File) => {
    if (file.size > FILE_SIZE_LIMIT) {
      dispatch({
        type: ACTIONS.SET_PROMPT,
        payload: {
          type: "warning",
          message: `
            ファイルのアップロードに失敗しました。<br/>
            ５MB以下のファイルを選択してください
          `,
        },
      });
      return;
    }

    setFilesToAnalyze((prevFiles) => [...prevFiles, file])
  };

  const onClickAnalyze = async () => {
    const jobPostId = job?.id!;
    let isAnalysisSuccessful = false;

    dispatch({
      type: ACTIONS.START_LOADING,
      payload: {
        message: `
          <strong>求人票データの読み取り中</strong><br/>
          読み取り完了まで数分かかることがあります。<br/>
          しばらくお待ち下さい
        `,
      },
    });

    try {
      const analyzedData = await analyzeJobDataByAI(jobPostId, { files: filesToAnalyze, url: urlToAnalyze });
      await updateJobData(jobPostId, analyzedData);
      isAnalysisSuccessful = true;
    } catch (e) {
      if (axios.isAxiosError(e)) {
        switch (e.response?.status) {
          case 422:
            //For now, we don't need to handle this error
            //because there is no spec/design for it (but probably will be in the future)
            const errorData = e.response.data as JobDataAIAnalysis422Error;
            break;
        }
      }

      dispatch({
        type: ACTIONS.SET_PROMPT,
        payload: {
          type: "warning",
          message: `
            <strong>データ読み取り失敗</strong><br/>
            求人票データの読み取りに失敗しました。お手数ですが、直接ご登録をお願いいたします。
          `,
        },
      });
    } finally {
      //We need to check the latest record because it's not clear
      //if the AI analysis succeeded/failed, or another unexpected error occurred
      if (jobPostId) {
        try {
          const jobUpToDate = await getJob(jobPostId);
          onChange?.({
            aiAnalyzeSuccessCount: jobUpToDate.aiAnalyzeSuccessCount,
            aiAnalyzeFailureCount: jobUpToDate.aiAnalyzeFailureCount,
          });
        } catch {}
      }

      if (isAnalysisSuccessful)
        dispatch({
          type: ACTIONS.SET_PROMPT,
          payload: {
            type: "success",
            message: `
              <strong>データ読み取り成功</strong><br/>
              求人票データの読み取りが完了しました。<br/>
              読み取り情報をご確認ください。
            `,
          },
        });
    }

    dispatch({ type: ACTIONS.STOP_LOADING });
  };

  const updateJobData = async (jobPostId: number, analyzedData: JobDataAIAnalyzed) => {
    const data: Partial<JobExtended> = {};

    if (analyzedData.name != null)
      data.name = analyzedData.name;

    if (analyzedData.numberOfPositions != null)
      data.numberOfPositions = analyzedData.numberOfPositions;

    if (isValidDateString(analyzedData.applicationDeadline))
      data.applicationDeadline = new Date(analyzedData.applicationDeadline).toISOString();

    if (analyzedData.plannedDateOfEmployment != null)
      data.plannedDateOfEmployment = analyzedData.plannedDateOfEmployment;

    switch (analyzedData.contractPeriod) {
      case JobContractPeriodFromAI.FixedTerm:
        data.contractPeriod = JobContractPeriod.FixedTerm;
        break;
      case JobContractPeriodFromAI.Indefinite:
        data.contractPeriod = JobContractPeriod.Indefinite;
        break;
    }

    if (analyzedData.contractPeriodYears != null)
      data.contractPeriodYears = analyzedData.contractPeriodYears;

    switch (analyzedData.contractRenewal) {
      case JobContractRenewalFromAI.Automatic:
        data.contractRenewal = JobContractRenewal.Automatic;
        break;
      case JobContractRenewalFromAI.Possible:
        data.contractRenewal = JobContractRenewal.Possible;
        break;
      case JobContractRenewalFromAI.No:
        data.contractRenewal = JobContractRenewal.No;
        break;
    }

    //"acceptanceOfDesignatedActivitiesVisa" is no longer used at frontend

    if (analyzedData.prefecture != null) {
      const pref = analyzedData.prefecture;
      const result = PREFECTURES_JA.find((p) => p === pref);
      if (result) {
        const index = PREFECTURES_JA.indexOf(result);
        data.ecPlaceOfEmploymentJaPrefecture = result;
        data.ecPlaceOfEmploymentEnPrefecture = PREFECTURES_EN[index];
      }
    }

    if (analyzedData.cityWard != null)
      data.ecPlaceOfEmploymentJaCityWard = analyzedData.cityWard;

    if (analyzedData.town != null)
      data.ecPlaceOfEmploymentJaTown = analyzedData.town;

    if (analyzedData.addressNumber != null) {
      data.jaAddressNumber = analyzedData.addressNumber;
      data.ecEmployerJaAddressNumber = analyzedData.addressNumber;
    }

    if (analyzedData.buildingName != null) {
      data.jaBuildingName = analyzedData.buildingName;
      data.ecEmployerJaBuilding = analyzedData.buildingName;
    }

    if (analyzedData.jobDescription != null)
      data.jobDescription = analyzedData.jobDescription;

    switch (analyzedData.salaryType) {
      case JobSalaryTypeFromAI.Monthly:
        data.salaryType = JobSalaryType.Monthly;
        data.ecBaseWageType = EcWagePaymentType.Monthly;
        break;
      case JobSalaryTypeFromAI.Daily:
        data.salaryType = JobSalaryType.Daily;
        data.ecBaseWageType = EcWagePaymentType.Daily;
        break;
      case JobSalaryTypeFromAI.Hourly:
        data.salaryType = JobSalaryType.Hourly;
        data.ecBaseWageType = EcWagePaymentType.Hourly;
        break;
    }

    //"annualizedVariableWorkingHourSystem" is no longer used at frontend

    if (analyzedData.hourlyDailySalary != null)
      data.hourlyDailySalary = analyzedData.hourlyDailySalary;

    if (analyzedData.grossSalary != null) {
      data.grossSalary = analyzedData.grossSalary;
      data.ecBaseWageAmount = analyzedData.grossSalary;
    }

    if (analyzedData.estimatedTotalDeductions != null) {
      data.estimatedTotalDeductions = analyzedData.estimatedTotalDeductions;
      data.ecDeductionsTotalDeductions = analyzedData.estimatedTotalDeductions;
    }

    if (analyzedData.estimatedSocialInsurance != null) {
      data.estimatedSocialInsurance = analyzedData.estimatedSocialInsurance;
      data.ecDeductionsSocialInsurancePremiums =
        analyzedData.estimatedSocialInsurance;
    }

    if (analyzedData.dormitoryFee != null) {
      data.dormitoryFee = analyzedData.dormitoryFee;
      data.ecDeductionsHousingExpenses = analyzedData.dormitoryFee;
    }

    if (analyzedData.estimatedIncomeTax != null) {
      data.estimatedIncomeTax = analyzedData.estimatedIncomeTax;
      data.ecDeductionsTaxes = analyzedData.estimatedIncomeTax;
    }

    if (analyzedData.takeHomeSalary != null) {
      data.takeHomeSalary = analyzedData.takeHomeSalary;
      data.ecTakeHomeWage = analyzedData.takeHomeSalary;
    }

    //"averageNightWorkHoursPerMonth" is no longer used at frontend

    //"averageTotalNightShiftPremiumWagesPerMonth" is no longer used at frontend

    if (analyzedData.salaryRaise != null) {
      data.salaryRaise = analyzedData.salaryRaise;
      data.ecCompensationWageRaise = analyzedData.salaryRaise;
    }

    if (analyzedData.bonus != null) {
      data.bonus = analyzedData.bonus;
      data.ecCompensationBonus = analyzedData.bonus;
    }

    if (analyzedData.numberOfBonusPaymentsPerYear != null) {
      data.numberOfBonusPaymentsPerYear = analyzedData.numberOfBonusPaymentsPerYear;
      data.ecCompensationBonusNumberOfPaymentsPerYear =
        analyzedData.numberOfBonusPaymentsPerYear;
    }

    if (analyzedData.basicWorkHoursPerDay != null) {
      data.basicWorkHoursPerDay = analyzedData.basicWorkHoursPerDay;
      data.ecWorkHoursNormalWorkingHour = analyzedData.basicWorkHoursPerDay;
    }

    if (analyzedData.basicWorkMinutesPerDay != null) {
      data.basicWorkMinutesPerDay = analyzedData.basicWorkMinutesPerDay;
      data.ecWorkHoursNormalWorkingMinute = analyzedData.basicWorkMinutesPerDay;
    }

    switch (analyzedData.workStyle) {
      case JobWorkStyleFromAI.FixedHours:
        data.workStyle = JobWorkStyle.FixedHours;
        data.ecWorkHoursShiftSystem = false;
        break;
      case JobWorkStyleFromAI.ShiftSystem:
        data.workStyle = JobWorkStyle.ShiftSystem;
        data.ecWorkHoursShiftSystem = true;
        break;
    }

    if (analyzedData.averageMonthlyWorkDays != null)
      data.averageMonthlyWorkDays = analyzedData.averageMonthlyWorkDays;

    if (analyzedData.averageMonthlyOvertimeHours != null)
      data.averageMonthlyOvertimeHours = analyzedData.averageMonthlyOvertimeHours;

    if (analyzedData.averageAnnualDaysOff != null) {
      data.averageAnnualDaysOff = analyzedData.averageAnnualDaysOff;
      data.ecHolidaysAnnualNumberOfHolidays = analyzedData.averageAnnualDaysOff;
    }

    if (analyzedData.dormitory != null)
      data.dormitory = analyzedData.dormitory;

    switch (analyzedData.dormitoryType) {
      case JobDormTypeFromAI.Apartment:
        data.dormitoryType = JobDormType.Apartment;
        break;
      case JobDormTypeFromAI.SingleHouse:
        data.dormitoryType = JobDormType.SingleHouse;
        break;
    }

    if (analyzedData.bedroomPersons != null)
      data.bedroomPersons = analyzedData.bedroomPersons;

    //"personWhoContractsLease" is no longer used at frontend

    switch (analyzedData.commuteMethod) {
      case JobCommuteMethodFromAI.Walk:
        data.commuteMethod = JobCommuteMethod.Walk;
        break;
      case JobCommuteMethodFromAI.Bycycle:
        data.commuteMethod = JobCommuteMethod.Bicycle;
        break;
      case JobCommuteMethodFromAI.PublicTransport:
        data.commuteMethod = JobCommuteMethod.PublicTransport;
        break;
      case JobCommuteMethodFromAI.Automobile:
        data.commuteMethod = JobCommuteMethod.Automobile;
        break;
    }

    if (analyzedData.commuteMinutes != null)
      data.commuteMinutes = analyzedData.commuteMinutes;

    if (analyzedData.furnitureAppliances != null)
      data.furnitureAppliances = analyzedData.furnitureAppliances;

    if (analyzedData.transportationMovingExpensesCovered != null)
      data.transportationMovingExpensesCovered =
        analyzedData.transportationMovingExpensesCovered;

    if (analyzedData.transportationMovingExpensesMaximumSupportAllowance != null)
      data.transportationMovingExpensesMaximumSupportAllowance =
        analyzedData.transportationMovingExpensesMaximumSupportAllowance;

    if (analyzedData.baggageDeliveryMovingExpensesCovered != null)
      data.baggageDeliveryMovingExpensesCovered =
        analyzedData.baggageDeliveryMovingExpensesCovered;

    if (analyzedData.baggageDeliveryMovingExpensesMaximumSupportAllowance != null)
      data.baggageDeliveryMovingExpensesMaximumSupportAllowance =
        analyzedData.baggageDeliveryMovingExpensesMaximumSupportAllowance;

    switch (analyzedData.japaneseConversationSkills) {
      case JobJaConversationSkillFromAI.CanSpeakBasicPhrases:
        data.japaneseConversationSkills =
          JobJPConversationSkill.CanSpeakBasicPhrases;
        break;
      case JobJaConversationSkillFromAI.CanCarryOnEverydayConversation:
        data.japaneseConversationSkills =
          JobJPConversationSkill.CanCarryOnEverydayConversation;
        break;
      case JobJaConversationSkillFromAI.Fluent:
        data.japaneseConversationSkills = JobJPConversationSkill.Fluent;
        break;
    }

    switch (analyzedData.jlptLevel) {
      case JobJlptLevelFromAI.N1:
        data.jlptLevel = JobJlptLevel.N1;
        break;
      case JobJlptLevelFromAI.N2:
        data.jlptLevel = JobJlptLevel.N2;
        break;
      case JobJlptLevelFromAI.N3:
        data.jlptLevel = JobJlptLevel.N3;
        break;
      case JobJlptLevelFromAI.N4:
        data.jlptLevel = JobJlptLevel.N4;
        break;
      case JobJlptLevelFromAI.N5:
        data.jlptLevel = JobJlptLevel.N5;
        break;
    }

    switch (analyzedData.preferredGender) {
      case JobPreferredGenderFromAI.NotAny:
        data.preferredGender = JobPreferredGender.NotAny;
        break;
      case JobPreferredGenderFromAI.Male:
        data.preferredGender = JobPreferredGender.Male;
        break;
      case JobPreferredGenderFromAI.Female:
        data.preferredGender = JobPreferredGender.Female;
        break;
    }

    if (analyzedData.preferredAgeFrom != null)
      data.preferredAgeFrom = analyzedData.preferredAgeFrom;

    if (analyzedData.preferredAgeTo != null)
      data.preferredAgeTo = analyzedData.preferredAgeTo;

    if (analyzedData.requiredConditions != null)
      data.requiredConditions = analyzedData.requiredConditions;

    if (analyzedData.preferredConditions != null)
      data.preferredConditions = analyzedData.preferredConditions;

    if (analyzedData.specialAllowanceUponHire != null)
      data.specialAllowanceUponHire = analyzedData.specialAllowanceUponHire;

    switch (analyzedData.interviewMethod) {
      case JobInterviewTypeFromAI.Online:
        data.interviewMethod = JobInterviewType.Online;
        break;

      case JobInterviewTypeFromAI.Offline:
        data.interviewMethod = JobInterviewType.Offline;
        break;
    }

    if (analyzedData.provideFullTransportationExpenses != null)
      data.provideFullTransportationExpenses =
        analyzedData.provideFullTransportationExpenses;

    if (analyzedData.availableDaysAndTimesForInterviews != null)
      data.availableDaysAndTimesForInterviews =
        analyzedData.availableDaysAndTimesForInterviews;

    if (analyzedData.otherSupplementaryInformationAboutThisJob != null)
      data.otherSupplementaryInformationAboutThisJob =
        analyzedData.otherSupplementaryInformationAboutThisJob;

    if (analyzedData.acceptOverseasResidents != null)
      data.acceptOverseasResidents = analyzedData.acceptOverseasResidents;

    if (analyzedData.grossSalaryUpperLimit != null)
      data.grossSalaryUpperLimit = analyzedData.grossSalaryUpperLimit;

    if (analyzedData.hourlyDailySalaryUpperLimit != null)
      data.hourlyDailySalaryUpperLimit = analyzedData.hourlyDailySalaryUpperLimit;

    if (!isEmpty(data)) {
      const updated = await updateJob(jobPostId, data);
      onChange?.(updated);
    }
  };

  return (
    <>
      <GridRowsContainer>
        <GridRow>
          <LabelCell>
            <Label>既存URLから作成</Label>
          </LabelCell>
          <FieldCell>
            <Column10>
              <LocalTextInputExtend
                placeholder="公開している求人票のURLを入力"
                value={urlToAnalyze}
                onTextChange={setUrlToAnalyze}
                disabled={disableUrlInput}
              />
              <NoteList>
                <NoteListItem>
                  <Note>
                    求人票URLがログイン必須などの条件により、外部参照できない可能性があります。あらかじめご了承ください。
                  </Note>
                </NoteListItem>
              </NoteList>
            </Column10>
          </FieldCell>
        </GridRow>
        <GridRow>
          <LabelCell>
            <Label>既存ファイルから作成</Label>
          </LabelCell>
          <FieldCell>
            <Column10>
                {[...Array(numOfFiles)].map((_, index) => (
                  <LocalRow key={index}>
                    <LocalUploadButton
                      disabled={disableFileUpload || !!filesToAnalyze[index]}
                      variant="secondary"
                      buttonText={`ファイルを選択 ${index + 1}`}
                      onFileChange={onFileChange}
                      accept=".docx,.xls,.xlsx,.pdf,.jpg,.jpeg,.png,.webp,.bmp,.tiff,.tif,.gif,.txt"
                    />
                    {filesToAnalyze[index] && (
                      <Stack>
                        <DeleteIcon onClick={() => setFilesToAnalyze((prevFiles) => prevFiles.filter((_, i) => i !== index))} />
                        <Text>{filesToAnalyze[index].name}</Text>
                      </Stack>
                    )}
                  </LocalRow>
                ))}
              <NoteList>
                <NoteListItem>
                  <Note>
                    求人票のファイルからテキストを読み取り、求人票の作成を支援します。最大1ファイル、5MBまでアップロードできます。
                  </Note>
                </NoteListItem>
                <NoteListItem>
                  <Note>
                    ファイルの解像度などの条件次第では読み取り精度が低くなる可能性があります。あらかじめご了承ください。
                  </Note>
                </NoteListItem>
              </NoteList>
            </Column10>
          </FieldCell>
        </GridRow>
        <GridRow>
          <LocalFullRowCell>
            <AlertBanner>
              <AlertIcon
                style={{ flexShrink: 0}}
              />
              <Text>
                1求人あたりの求人票の取り込みは、失敗時を含めて最大{analysisAttemptLimit}回までです。
              </Text>
            </AlertBanner>
            <Button disabled={!isReadyToAnalyze} onClick={onClickAnalyze}>
              {succeededAtLeastOnce
                ? "上書取込して下書き保存"
                : "データ取込して下書き保存"}
            </Button>
          </LocalFullRowCell>
        </GridRow>
      </GridRowsContainer>
    </>
  );
};

export default AiAnalysisSection;

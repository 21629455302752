import { FunctionComponent } from "react";
import {
  InputContainer,
  ItemLabel,
  FormRow,
  FormSection,
  ItemTextInput,
  SectionHeading,
  LabelContainer,
} from "../../commonStyles";
import { FormBlockSectionProps } from "../../types";

interface EmployeeSectionProps extends FormBlockSectionProps {}

const EmployeeSection: FunctionComponent<EmployeeSectionProps> = ({
  employmentConditions: ec,
  onChange
}) => {

  return (
    <FormSection>
      <SectionHeading>被用者名</SectionHeading>
      <FormRow>
        <LabelContainer>
          <ItemLabel>氏名（英字）</ItemLabel>
        </LabelContainer>
        <InputContainer>
          <ItemTextInput 
            placeholder="Nguyen Nam Van" 
            value={ec?.employeeEnFullName ?? ""}
            onTextChange={val => onChange?.({ employeeEnFullName: val})}
          />
        </InputContainer>
      </FormRow>
    </FormSection>
  );
};

export default EmployeeSection;

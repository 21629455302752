import { FunctionComponent, HTMLAttributes } from 'react';
import styled from 'styled-components';

import { ReactComponent as SuccessIcon } from '../../assets/icon-green-check-white-filled.svg';
import { ReactComponent as AlertIcon } from '../../assets/icon-warning-banner.svg';
import { ReactComponent as InfoMessageIcon } from '../../assets/icon-info-message.svg';
import { ReactComponent as CrossIcon } from '../../assets/icon-cross.svg';
import { MOBILE_DEVICE } from '../constants/styles';

const BackgroundColors = {
    default: 'rgba(34, 122, 255, 1)',
    success: 'rgba(17, 146, 68, 1)',
    warning: 'rgba(222, 0, 29, 1)',
    neutral: 'rgba(34, 122, 255, 1)',
};

type SnackbarType = 'success' | 'warning' | 'neutral';

interface SnackbarProps extends HTMLAttributes<HTMLDivElement> {
    type?: SnackbarType;
    onClose?: () => void;
    header?: string;
}

interface ContainerProps {
    type?: SnackbarType;
}

interface CloseIconProps {
    type?: SnackbarType;
}

const Container = styled.div<ContainerProps>`
  width: 300px;
  position: fixed;
  top: 10px;
  left: 50%;
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  justify-content: center;
  gap: 8px;
  min-height: 40px;
  height: auto;
  padding: 8px;
  border-radius: 4px;
  border: ${(props: ContainerProps) => {
    switch (props.type) {
      case 'neutral':
        return '1px solid #E3F3FE';
      default:
        return '1px solid transparent'
    }
  }};
  background: ${(props: ContainerProps) => (
      BackgroundColors[props.type ?? 'default'] ?? BackgroundColors.default
  )};
  transition: all 0.4s;
  transform: translateX(-50%);
  z-index: 10;

  animation-name: slideinout;
  animation-duration: 5s;
  animation-fill-mode: forwards;

  @media ${MOBILE_DEVICE} {
    padding-left: 16px;
    padding-right: 16px;
  }

  @keyframes slideinout {
    0% {
      top: -40px;
      opacity: 0;
    }
    10% {
      top: 5px;
      opacity: 1;
    }
    85% {
      top: 5px;
      opacity: 1;
    }
    100% {
      top: -40px;
      opacity: 0;
    }
  }

  @media print {
    display: none;
  }
`;

const BodyContainer = styled.div`
  display: flex;
  gap: 4px;
  flex-direction: column;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: ${(props: ContainerProps) => {
    switch (props.type) {
      case 'success':
        return 'rgba(255, 255, 255, 1)';
      case 'warning':
        return 'rgba(255, 255, 255, 1)';
      case 'neutral':
        return 'rgba(11, 104, 203, 1)';
      default:
        return 'rgba(255, 255, 255, 1)';
    }
  }};
`;

const Header = styled.div`
  color: inherit;
  font-size: inherit;
  font-weight: 700;
`;

const Content = styled.div`
  color: inherit;
  font-size: inherit;
  word-break: break-all;
`;

const IconContainer = styled.div`
  width: 24px;
  height: 24px;
`;

const WarningIcon = styled(AlertIcon)`
  & * path {
    fill: rgba(255, 255, 255, 1)
  }
`;

const InfoIcon = styled(InfoMessageIcon)`
  > path {
    fill: #cb5e0b;
  }
`;

const CloseIcon = styled(CrossIcon)<CloseIconProps>`
  cursor: pointer;
  > path {
    fill: ${(props: CloseIconProps) => {
      switch (props.type) {
        case 'success':
        case 'warning':
          return 'white';
        default:
            return '#0B68CB';
      }
    }};
  }
`;

const Snackbar: FunctionComponent<SnackbarProps> = ({
    type,
    onClose,
    header,
    children: content,
}) => {
  return (
      <Container type={type}>
        <IconContainer>
          {(type === 'success') && (
            <SuccessIcon />
          )}
          {(type === 'warning') && (
            <WarningIcon />
          )}
          {(type === 'neutral') && (
            <InfoIcon />
          )}
        </IconContainer>

        <BodyContainer type={type}>
          {header && (<Header>{header}</Header>)}
          <Content>{content}</Content>
        </BodyContainer>

        {onClose && (
          <CloseIcon onClick={onClose} type={type}/>
        )}
      </Container>
  );
};

export default Snackbar;

import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { LanguageCode } from "../../../../../core/enums/language";
import {
  FormHeaderContainer,
  FormHeader,
  FormHeaderTextInJapanese,
  FormHeaderTextInTransLang,
} from "../../commonStyles";

type WagePaymentHeaderProps = {
  languageCode: LanguageCode;
}

const WagePaymentHeader: FunctionComponent<WagePaymentHeaderProps> = ({ languageCode }) => {
  const { i18n } = useTranslation();
  const compPrefix = "proposal.compensations";
  const tFixedWithComp = i18n.getFixedT(languageCode, "", compPrefix);
  const tFixedComp = (key: string): string => tFixedWithComp(key).toString();

  return (
    <FormHeaderContainer>
      <div>
        <span>参考様式第１－６号 別紙</span>
        <br />
        <span>{tFixedComp("ref_format_no_1_6")}</span>
      </div>
      <FormHeader>
        <FormHeaderTextInJapanese>
          <span>賃</span>
          <span>金</span>
          <span>の</span>
          <span>支</span>
          <span>払</span>
        </FormHeaderTextInJapanese>
        <FormHeaderTextInTransLang>
          {tFixedComp("main_header")}
        </FormHeaderTextInTransLang>
      </FormHeader>
    </FormHeaderContainer>
  )
}


export default WagePaymentHeader;

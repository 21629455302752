import { apiUtil } from "../../core/utils";
import { EmploymentCondition, EmploymentConditionExtended } from "../types";

export const createProposalEmploymentConditions = async (proposalId: string | number, ec?: Partial<EmploymentConditionExtended>) => {
  const response = await apiUtil.post(
      '/proposal_employment_conditions',
      {
        proposalId,
        ...ec
      },
  );
  if (response.status >= 200 && response.status < 300) {
      return response.data as EmploymentCondition;
  }
  throw new Error('API Error');
};

export const getProposalEmploymentConditions = async (id: string | number) => {
  const response = await apiUtil.get(
      `/proposal_employment_conditions/${id}`,
  );
  if (response.status >= 200 && response.status < 300) {
      return response.data as EmploymentCondition;
  }
  throw new Error('API Error');
}

export const updateProposalEmploymentConditions = async (ecId: string | number, ec: Partial<EmploymentConditionExtended>) => {
  const {
    //Unnecessary fields
    id,
    createdAt,
    updatedAt,
    proposalId,
    bonusMonths,
    translations,
    emailSentAt,

    //Extract only the necessary fields
    ...targetUpdateData
  } = ec;

  const response = await apiUtil.put(
      `/proposal_employment_conditions/${ecId}`,
      targetUpdateData,
  );
  if (response.status >= 200 && response.status < 300) {
      return response.data as EmploymentCondition;
  }
  throw new Error('API Error');
}

export const sendEmploymentConditionsEmail = async (ecId: string | number) => {
  const response = await apiUtil.post(
      `/proposal_employment_conditions/${ecId}/send_proposal_employment_condition`,
  );

  if (response.status >= 200 && response.status < 300) {
      return response.data as EmploymentCondition;
  }
  throw new Error('API Error');
}

export const validateEmploymentConditions = async (ecId: number|string) => {
  return await apiUtil.get(`/proposal_employment_conditions/${ecId}/validate_employment_conditions`);
}

import { User } from '../types/api';
import { apiUtil } from '../utils';

export const signIn = async (email: string, password: string) => {
    const response = await apiUtil.post(
        '/users/sign_in',
        {
            user: {
                email,
                password,
            },
        },
    );
    if (response.status >= 200 && response.status < 300) {
        return response.data as User;
    }
    throw new Error('API Error');
};

export const signInAs = async (nonce: string) => {
    const response = await apiUtil.post(
        '/users/sessions/sign_in_as',
        {
            nonce
        }
    );
    if (response.status >= 200 && response.status < 300) {
        return response.data as User;
    }
    throw new Error('API Error');
}

export const signOut = async () => {
    const response = await apiUtil.delete('/users/sign_out');
    if (response.status >= 200 && response.status < 300) {
        return response.data;
    }
    throw new Error('API Error');
};

export const getCurrentUser = async () => {
    const response = await apiUtil.get('/users/current');
    if (response.status >= 200 && response.status < 300 && response.data) {
        return response.data as User;
    }
    throw new Error('API Error');
};
import { ButtonHTMLAttributes, FunctionComponent, ReactNode } from 'react';
import styled from 'styled-components';
import { ReactComponent as PlusIcon } from '../../assets/icon-plus.svg';
import { MOBILE_DEVICE } from '../constants/styles';
import { ButtonVariant } from '../types/component';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
    variant?: ButtonVariant;
    children?: ReactNode;
};

type ContainerProps = ButtonProps & {
};

const Container = styled.button<ContainerProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  height: ${(props: ContainerProps) => {
    switch (props.variant) {
      case 'quart': return '30px';
      case 'quart-add': return '30px';
      case 'quarternary-subtle': return '30px';
      case 'primary-small': return '30px';
      case 'inline': return '26px';
      default: return '40px';
    }
  }};
  padding: ${(props: ContainerProps) => {
    switch (props.variant) {
      case 'primary': return '8.5px 20px';
      case 'primary-small': return '5px 20px';
      case 'secondary': return '8.5px 20px';
      case 'thirdly': return '8.5px 20px';
      case 'tertiary': return '8.5px 20px';
      case 'quart': return '5px 20px';
      case 'quart-add': return '5px 10px';
      case 'inline': return '3px 20px';
      default: return '0 36px';
    }
  }};
  font-size: ${(props: ContainerProps) => {
    switch (props.variant) {
      case 'quart': return '14px';
      case 'quart-add': return '14px';
      case 'quarternary-subtle': return '14px';
      case 'primary-small': return '14px';
      case 'inline': return '14px';
      default: return '16px';
    }
  }};
  font-weight: ${(props: ContainerProps) => {
    switch (props.variant) {
      case 'quart': return 400;
      case 'quart-add': return 400;
      case 'quarternary-subtle': return 400;
      case 'primary-small': return 400;
      case 'secondary': return 500;
      case 'inline': return 500;
      default: return 'bold';
    }
  }};
  color: ${(props: ContainerProps) => {
    switch (props.variant) {
      case 'secondary': return '#444444';
      case 'thirdly':   return '#FFFFFF';
      case 'tertiary' : return '#444444';
      case 'quart'    : return '#444444';
      case 'quart-add': return '#444444';
      case 'quarternary-subtle': return '#444444';
      case 'inline': return '#444444';
      default: return '#FFFFFF';
    }
  }};
  background: ${(props: ContainerProps) => {
    switch (props.variant) {
      case 'secondary': return "#FFFFFF";
      case 'thirdly':   return '#0E8CF1';
      case 'quart': return "#E5E5E5";
      case 'quart-add': return "#FFFFFF";
      case 'tertiary':  return "#E5E5E5";
      case 'quarternary-subtle': return "transparent";
      case 'inline':  return "#EDEDED";
      default: return "#FF730D";
    }
  }};
  border: 1px solid ${(props: ContainerProps) => {
    switch (props.variant) {
      case 'secondary': return "#D5D5D5";
      case 'thirdly':   return "#0E8CF1";
      case 'quart':     return "#D5D5D5";
      case 'quart-add': return "#D5D5D5";
      case 'tertiary':  return "#C4C4C4";
      case 'quarternary-subtle': return "transparent";
      case 'inline':  return "#C2C2C2";
      default: return "#FF730D";
    }
  }};
  border-radius: 5px;
  box-sizing: border-box;
  outline: none;
  white-space: nowrap;

  &:hover {
    color: ${(props: ContainerProps) => {
      switch (props.variant) {
        case 'secondary': return "#444444";
        case 'thirdly':   return "#0E8CF1";
        case 'tertiary':  return "#444444";
        case 'quart':     return "#FF730D";
        case 'quart-add': return "#FF730D";
        case 'quarternary-subtle': return "#444444";
        case 'primary-small': return "#FF9E57";
        case 'inline': return "#444444";
        default:          return "#FF730D";
      }
    }};
    background: ${(props: ContainerProps) => {
      switch (props.variant) {
        case 'secondary': return "#EDEDED";
        case 'tertiary':  return "#D5D5D5";
        case 'quart':     return "#FFDFD1";
        case 'quart-add': return "#FFDFD1";
        case 'quarternary-subtle': return "#E5E5E5";
        case 'inline': return "#F7F7F7";
        default:          return "#FFFFFF";
      }
    }};
    border-color: ${(props: ContainerProps) => {
      switch (props.variant) {
        case 'secondary': return "#D5D5D5";
        case 'thirdly':   return "#0E8CF1";
        case 'tertiary':  return "#D5D5D5";
        case 'quart':     return "#D5D5D5";
        case 'quart-add' : return "#D5D5D5";
        case 'quarternary-subtle' : return "transparent";
        case 'inline': return "#C2C2C2";
        default:          return "inherit";
      }
    }};
  }

  &:focus, &:disabled {
    color: ${(props: ContainerProps) => {
      switch (props.variant) {
        case 'secondary': return "#D5D5D5";
        case 'thirdly':   return "#0E8CF1";
        case 'tertiary':  return "#999999";
        case 'quart':     return "#999999";
        case 'quart-add': return "#FF730D";
        case 'quarternary-subtle': return "#444444";
        case 'inline': return "#444444";
        default:          return "#FFC194";
      }
    }};
    background: ${(props: ContainerProps) => {
      switch (props.variant) {
        case 'secondary': return "#999999";
        case 'thirdly':   return '#FFF';
        case 'tertiary':  return "#D5D5D5";
        case 'quart':     return "#D5D5D5";
        case 'quart-add': return "#FFDFD1";
        case 'quarternary-subtle': return "transparent";
        case 'inline': return "#F7F7F7";
        default:          return "#FF730D";
      }
    }};
  }

  &:active {
    color: ${(props: ContainerProps) => {
      switch (props.variant) {
        case 'secondary': return "#D5D5D5";
        case 'thirdly': return "#0E8CF1";
        case 'tertiary': return "#999999";
        case 'quart': return "#FF730D";
        case 'quart-add': return "#FF730D";
        case 'quarternary-subtle': return "#444444";
        case 'primary-small': return "#FF9E57";
        case 'inline': return "#444444";
        default: return "#FFC194";
      }
    }};

    background: ${(props: ContainerProps) => {
      switch (props.variant) {
        case 'thirdly': return '#FFF';
        case 'quart': return '#FFC194';
        case 'quart-add': return '#FFDFD1';
        case 'quarternary-subtle': return "#C4C4C4";
        case 'primary-small': return "#E5680C";
        case 'tertiary': return "#D5D5D5";
      }
    }};

    border-color: ${(props: ContainerProps) => {
      switch (props.variant) {
        case 'secondary': return "#0E8CF1";
        case 'tertiary': return "transparent";
        case 'quarternary-subtle' : return "transparent";
        case 'quart': return "transparent";
        case 'quart-add': return "#FFC194";
      }
    }};

    outline: ${(props: ContainerProps) => {
      switch (props.variant) {
        case 'quart-add': return '1px solid #FFC194';
        default: return 'none';
      }
    }};
  }

  //"path" below is intended to use for the color of the SVG icon inside
  &:hover path {
    fill: ${(props: ContainerProps) => {
      switch (props.variant) {
        case 'secondary': return "#FFFFFF";
        case 'tertiary':  return "#444444";
        case 'quart':     return "#FF730D";
        case 'quart-add': return "#FF730D";
        case 'quarternary-subtle': return "#444444";
        case 'primary-small': return "#FF9E57";
        default:          return "#FF730D";
      }}};
  }

  &:active, &:focus path {
    fill: ${(props: ContainerProps) => {
      switch (props.variant) {
        case 'secondary': return "#D5D5D5";
        case 'tertiary': return "#999999";
        case 'quart': return "#FF730D";
        case 'quart-add': return "#FF730D";
        case 'quarternary-subtle': return "#444444";
        case 'primary-small': return "#FF9E57";
        default: return "#FFC194";
      }
    }};
  }

  &:disabled {
    cursor: not-allowed;
  }

  @media ${MOBILE_DEVICE} {
    width: 100%;
  }
`;

const Button: FunctionComponent<ButtonProps> = ({
    children,
    ...props
}) => {
    return (
        <Container {...props}>
            { props?.variant === 'quart-add' && <PlusIcon style={{ marginRight: 10 }}/>}
            {children}
        </Container>
    );
};

export default Button;

import { FunctionComponent, ReactNode, useEffect, useState } from "react";
import {
  Segment,
  RowSpaceBetween,
  Item20,
  Row,
  Item5,
  InputValue,
  FlexRowColumn,
  Item10,
} from "../../commonStyles";
import CheckMark from "../../components/CheckMark";
import {
  Section,
  Indent,
  ListBlock,
  ListBlockContent,
  SectionHeadingContainer,
  ListBlockIndex,
  ListBlocksContainer,
} from "./styles";
import Column from "../../../../../core/components/Column";
import { getDay, getMonth, getYear } from "../../util";
import { EcContractRenewal } from "../../../../../core/enums/employmentConditions";
import { TemplateSectionProps } from "../../types";
import { Trans, useTranslation } from "react-i18next";
import { isEmpty } from "../../../../../core/utils";

interface ContractPeriodSectionProps extends TemplateSectionProps {
  showECPeriod?: boolean
  showRenewalLimit?: boolean
}

const ContractPeriodSection: FunctionComponent<ContractPeriodSectionProps> = ({
  employmentCondition: ec,
  ecTranslationHandler: transHandler,
  showECPeriod = true,
  showRenewalLimit = true,
  i18nFormPrefixKey
}) => {
  const { i18n } = useTranslation();
  const transLang = transHandler.targetLangCode;
  const tOptions = { lng: transLang };
  const tFixedToTransLang = i18n.getFixedT(transLang, "", i18nFormPrefixKey);
  const tFixed = (key: string, opt?: object): string => tFixedToTransLang(key, opt).toString();
  const getTKey = (key: string) => `${i18nFormPrefixKey}.${key}`;
  const [contractStartDate, setContractStartDate] = useState<string>("");
  const [contractEndDate, setContractEndDate] = useState<string>("");
  const [expectedDateOfEntry, setExpectedDateOfEntry] = useState<string | null>("");
  const [contractRenewal, setContractRenewal] = useState<string>("");
  const [criteriaWorkload, setCriteriaWorkload] = useState<boolean>();
  const [criteriaPerformance, setCriteriaPerformance] = useState<boolean>();
  const [criteriaAbility, setCriteriaAbility] = useState<boolean>();
  const [criteriaCompanySituation, setCriteriaCompanySituation] = useState<boolean>();
  const [criteriaProgress, setCriteriaProgress] = useState<boolean>();
  const [criteriaOther, setCriteriaOther] = useState<boolean>();
  const [otherCriteriaForRenewal, setOtherCriteriaForRenewal] = useState<string>("");
  const [otherCriteriaForRenewalTrans, setOtherCriteriaForRenewalTrans] = useState<string>("");
  const [contractRenewalUpdateLimit, setContractRenewalUpdateLimit] = useState<boolean>();
  const [contractRenewalLimitPresenceText, setContractRenewalLimitPresenceText] = useState<string>("");
  const [contractRenewalLimitPresentedTextTrans, setContractRenewalLimitPresentedTextTrans] = useState<string>("");
  const [contractRenewalLimitNum, setContractRenewalLimitNum] = useState<number | null>();
  const [contractRenewalLimitTotalYears, setContractRenewalLimitTotalYears] = useState<number | null>();
  const [conversionToPermanentOffer, setConversionToPermanentOffer] = useState<boolean>();
  const [conversionToPermanentOfferText, setConversionToPermanentOfferText] = useState<string>("");
  const [conversionToPermanentOfferTextTrans, setConversionToPermanentOfferTextTrans] = useState<string>("");
  const [permanentOfferStartDate, setPermanentOfferStartDate] = useState<string>("");

  const EmpStartDateValue = ({ children }: { children?: ReactNode }) =>
    contractStartDate ?
      <Item5><InputValue>{ children }</InputValue></Item5> : <></>;

  const EmpEndDateValue = ({ children }: { children?: ReactNode }) =>
    contractEndDate ?
      <Item5><InputValue>{ children }</InputValue></Item5> : <></>;

  const ExpectedDateValue = ({ children }: { children?: ReactNode }) =>
    expectedDateOfEntry ?
      <Item5><InputValue>{ children }</InputValue></Item5> : <></>;

  const RenewalLimitPresence = ({ children }: { children?: ReactNode }) =>
    isEmpty(children) ?
      <Item20/> : <Item5>{ children }</Item5>;

  const RenewalLimitNum = ({ children }: { children?: ReactNode }) =>
    contractRenewalUpdateLimit && !isEmpty(children)?
      <Item5>{ children }</Item5> : <Item20/>;

  const RenewalLimitTotalYears = ({ children }: { children?: ReactNode }) =>
    contractRenewalUpdateLimit && !isEmpty(children) ?
      <Item5>{ children }</Item5> : <Item20/>;

  const RenewalLimitDetail = () =>
      contractRenewalUpdateLimit ?
      <Trans
          i18nKey={getTKey("renewal_limit_detail")}
          tOptions={tOptions}
          values={{
            limit_num: contractRenewalLimitNum,
            total_years: contractRenewalLimitTotalYears
          }}
          components={{
            seg: <Item5/>,
            limit_num: <RenewalLimitNum/>,
            total_years: <RenewalLimitTotalYears/>
          }}
      /> : <></>

  const ConversionToPermanenOffer = ({ children }: { children?: ReactNode }) =>
    isEmpty(children) ?
      <Item10/> : <Item5>{ children }</Item5>;

  const PermanentOfferStartYear = ({ children }: { children?: ReactNode }) =>
    isEmpty(children) ?
      <Item20 /> : <Item5>{ children }</Item5>;

  const PermanentOfferStartMonth = ({ children }: { children?: ReactNode }) =>
    isEmpty(children) ?
      <Item20 /> : <Item5>{ children }</Item5>;

  const PermanentOfferStartDay = ({ children }: { children?: ReactNode }) =>
    isEmpty(children) ?
      <Item20 /> : <Item5>{ children }</Item5>;


  useEffect(() => {
    if (!ec)
      return;

    setContractStartDate(ec.contractPeriodStartDate);
    setContractEndDate(ec.contractPeriodEndDate);
    setExpectedDateOfEntry(ec.contractPeriodScheduledEntryDate);
    setContractRenewal(ec.contractPeriodRenewal);
    setContractRenewalUpdateLimit(ec.contractPeriodRenewalUpdateLimit);

    if (ec.contractPeriodRenewal === EcContractRenewal.ConditionalRenewal) {
      setCriteriaWorkload(ec.contractPeriodRenewalConditionWorkload);
      setCriteriaPerformance(ec.contractPeriodRenewalConditionEmployeePerformance);
      setCriteriaAbility(ec.contractPeriodRenewalConditionEmployeeAbility);
      setCriteriaCompanySituation(ec.contractPeriodRenewalConditionCompanySituation);
      setCriteriaProgress(ec.contractPeriodRenewalConditionProgressSituation);
      setCriteriaOther(ec.contractPeriodRenewalConditionOther);
    }

    setOtherCriteriaForRenewal(ec.contractPeriodRenewalConditionOtherDetail);
    setOtherCriteriaForRenewalTrans(
      transHandler.getTranslation("contractPeriodRenewalConditionOtherDetail")
    );
    setContractRenewalUpdateLimit(ec.contractPeriodRenewalUpdateLimit);

    switch (ec.contractPeriodRenewalUpdateLimit) {
      case true:
        setContractRenewalLimitPresenceText("有");
        setContractRenewalLimitPresentedTextTrans(tFixed("yes"));
        break;
      case false:
        setContractRenewalLimitPresenceText("無");
        setContractRenewalLimitPresentedTextTrans(tFixed("no"));
        break;
    }

    setContractRenewalLimitNum(ec.contractPeriodRenewalNumberOfUpdates);
    setContractRenewalLimitTotalYears(ec.contractPeriodRenewalTotalContractPeriod);
    setConversionToPermanentOffer(ec.contractPeriodRenewalConditionsChange);

    switch (ec.contractPeriodRenewalConditionsChange) {
      case true:
        setConversionToPermanentOfferText("有");
        setConversionToPermanentOfferTextTrans(tFixed("yes"));
        break;
      case false:
        setConversionToPermanentOfferText("無");
        setConversionToPermanentOfferTextTrans(tFixed("no"));
        break;
    }

    if (ec.contractPeriodEndDate) {
      //Need to calculate the date: "本契約期間の末日の翌日"
      const nextDay = new Date(ec.contractPeriodEndDate);
      nextDay.setDate(nextDay.getDate() + 1);
      setPermanentOfferStartDate(nextDay.toISOString());
    }

  }, [ec]);

  return (
    <Section>
      <SectionHeadingContainer>
        <span>I.</span>
        <Segment>
          <span>雇用契約期間</span>
          <br />
          <span>{tFixed("employment_contract_period")}</span>
        </Segment>
      </SectionHeadingContainer>
      <Indent>
        <ListBlocksContainer>
          {showECPeriod && (
            <ListBlock>
              <ListBlockIndex>1.</ListBlockIndex>
              <ListBlockContent>
                <Segment>
                  <span>雇用契約期間</span>
                  <br />
                  <span>{tFixed("employment_contract_period")}</span>
                </Segment>
                <Segment>
                  <Row>
                    <Item20>
                      <Row>
                        <Item5>（</Item5>
                        <Item5><InputValue>{getYear(contractStartDate)}</InputValue></Item5>
                        <Item5>年</Item5>
                        <Item5><InputValue>{getMonth(contractStartDate)}</InputValue></Item5>
                        <Item5>月</Item5>
                        <Item5><InputValue>{getDay(contractStartDate)}</InputValue></Item5>
                        <Item5>日</Item5>
                        <Item5>〜</Item5>
                        <Item5><InputValue>{getYear(contractEndDate)}</InputValue></Item5>
                        <Item5>年</Item5>
                        <Item5><InputValue>{getMonth(contractEndDate)}</InputValue></Item5>
                        <Item5>月</Item5>
                        <Item5><InputValue>{getDay(contractEndDate)}</InputValue></Item5>
                        <Item5>日</Item5>
                        <Item5>）</Item5>
                      </Row>
                    </Item20>
                    <Item20>
                      <Row>
                        <Item5>入国予定日</Item5>
                        <Item5><InputValue>{getYear(expectedDateOfEntry)}</InputValue></Item5>
                        <Item5>年</Item5>
                        <Item5><InputValue>{getMonth(expectedDateOfEntry)}</InputValue></Item5>
                        <Item5>月</Item5>
                        <Item5><InputValue>{getDay(expectedDateOfEntry)}</InputValue></Item5>
                        <Item5>日</Item5>
                      </Row>
                    </Item20>
                  </Row>
                  <Row>
                    <Item20>
                      <Row>
                        <Trans
                          i18nKey={getTKey("employment_period")}
                          tOptions={tOptions}
                          values={{
                            s_day: getDay(contractStartDate),
                            s_month: getMonth(contractStartDate),
                            s_year: getYear(contractStartDate),
                            e_day: getDay(contractEndDate),
                            e_month: getMonth(contractEndDate),
                            e_year: getYear(contractEndDate)
                          }}
                          components={{
                            seg: <Item5/>,
                            s_val: <EmpStartDateValue />,
                            e_val: <EmpEndDateValue />
                          }}
                        />
                      </Row>
                    </Item20>
                    <Item20>
                      <Row>
                        <Trans
                          i18nKey={getTKey("planned_entry_date")}
                          tOptions={tOptions}
                          values={{
                            day: getDay(expectedDateOfEntry),
                            month: getMonth(expectedDateOfEntry),
                            year: getYear(expectedDateOfEntry)
                          }}
                          components={{
                            title: <Item10/>,
                            seg: <Item5/>,
                            val: <ExpectedDateValue />
                          }}
                        />
                      </Row>
                    </Item20>
                  </Row>
                </Segment>
              </ListBlockContent>
            </ListBlock>
          )}
          <ListBlock>
            <ListBlockIndex>2.</ListBlockIndex>
            <ListBlockContent>
              <Segment>
                <span>契約更新の有無</span>
                <br />
                <span>{tFixed("contract_renewal_presence")}</span>
              </Segment>
              <Segment>
                <FlexRowColumn>
                  <Item20>
                    <CheckMark
                      checked={contractRenewal === EcContractRenewal.AutomaticRenewal}
                    >
                      自動的に更新する
                    </CheckMark>
                  </Item20>
                  <Item20>
                    <CheckMark
                      checked={contractRenewal === EcContractRenewal.ConditionalRenewal}
                    >
                      更新する場合があり得る
                    </CheckMark>
                  </Item20>
                  <Item20>
                    <CheckMark
                      checked={contractRenewal === EcContractRenewal.NoRenewal}
                    >
                      契約の更新はしない
                    </CheckMark>
                  </Item20>
                </FlexRowColumn>
                <FlexRowColumn>
                  <Item20>
                    <CheckMark
                      checked={contractRenewal === EcContractRenewal.AutomaticRenewal}
                    >
                      {tFixed("auto_renewal")}
                    </CheckMark>
                  </Item20>
                  <Item20>
                    <CheckMark
                      checked={contractRenewal === EcContractRenewal.ConditionalRenewal}
                    >
                      {tFixed("possible_renewal")}
                    </CheckMark>
                  </Item20>
                  <Item20>
                    <CheckMark
                      checked={contractRenewal === EcContractRenewal.NoRenewal}
                    >
                      {tFixed("no_renewal")}
                    </CheckMark>
                  </Item20>
                </FlexRowColumn>
              </Segment>
              <Segment>
                <span>
                  ※
                  上記契約の更新の有無を「更新する場合があり得る」とした場合の更新の判断基準は以下のとおりとする。
                </span>
                <br />
                <span>
                  {tFixed("criteria_if_renewal_possible")}
                </span>
              </Segment>
              <Segment>
                <RowSpaceBetween>
                  <Column>
                    <CheckMark
                      checked={criteriaWorkload}
                    >
                      契約期間満了時の業務量
                    </CheckMark>
                    <CheckMark
                      checked={criteriaPerformance}
                    >
                      労働者の勤務成績，態度
                    </CheckMark>
                    <CheckMark
                      checked={criteriaAbility}
                    >
                      労働者の業務を遂行する能力
                    </CheckMark>
                    <CheckMark
                      checked={criteriaCompanySituation}
                    >
                      会社の経営状況
                    </CheckMark>
                    <CheckMark
                      checked={criteriaProgress}
                    >
                      従事している業務の進捗状況
                    </CheckMark>
                    <CheckMark
                      checked={criteriaOther}
                    >
                      <Item5>その他</Item5>
                      <Item5>(</Item5>
                      <Item5>
                        <InputValue>{otherCriteriaForRenewal}</InputValue>
                      </Item5>
                      <Item5>)</Item5>
                    </CheckMark>
                  </Column>
                  <Column>
                    <CheckMark
                      checked={criteriaWorkload}
                    >
                      {tFixed("work_volume_at_contract_end")}
                    </CheckMark>
                    <CheckMark
                      checked={criteriaPerformance}
                    >
                      {tFixed("employee_performance_attitude")}
                    </CheckMark>
                    <CheckMark
                      checked={criteriaAbility}
                    >
                      {tFixed("employee_capability")}
                    </CheckMark>
                    <CheckMark
                      checked={criteriaCompanySituation}
                    >
                      {tFixed("company_financial_status")}
                    </CheckMark>
                    <CheckMark
                      checked={criteriaProgress}
                    >
                      {tFixed("task_progress_status")}
                    </CheckMark>
                    <CheckMark
                      checked={criteriaOther}
                    >
                      <Item5>
                        {tFixed("other")}
                      </Item5>
                      <Item5>(</Item5>
                      <Item5>
                        <InputValue>{otherCriteriaForRenewalTrans}</InputValue>
                      </Item5>
                      <Item5>)</Item5>
                    </CheckMark>
                  </Column>
                </RowSpaceBetween>
              </Segment>
            </ListBlockContent>
          </ListBlock>
          {showRenewalLimit && (
            <ListBlock>
              <ListBlockIndex>3.</ListBlockIndex>
              <ListBlockContent>
                <Segment>
                  <span>
                    <Item5>更新上限の有無（</Item5><RenewalLimitPresence>{contractRenewalLimitPresenceText}</RenewalLimitPresence>
                    {
                        contractRenewalUpdateLimit &&
                        <>
                          <Item5>（更新</Item5><RenewalLimitNum>{contractRenewalLimitNum}</RenewalLimitNum><Item5>回まで／通算契約期間</Item5>
                          <RenewalLimitTotalYears>{contractRenewalLimitTotalYears}</RenewalLimitTotalYears>年まで）
                        </>
                    }
                      <Item5>）</Item5>
                  </span>
                  <br />
                  <span>
                    <Trans
                      i18nKey={getTKey("renewal_limit_presence")}
                      tOptions={tOptions}
                      values={{
                        presence: contractRenewalLimitPresentedTextTrans,
                      }}
                      components={{
                        seg: <Item5/>,
                        presence: <RenewalLimitPresence/>,
                        detail: <RenewalLimitDetail/>
                      }}
                    />
                  </span>
                  <br />
                </Segment>
                <Segment>
                  <span>【労働契約法に定める同一の企業との間での通算契約期間が５年を超える有期雇用契約の締結の場合】</span>
                  <br/>
                  <span>
                    <Item5>
                      本契約期間中に会社に対して期間の定めのない雇用契約（無期雇用契約）の締結の申込みをすることにより、本契約期間の末日の翌日（
                    </Item5>
                      <PermanentOfferStartYear>{getYear(permanentOfferStartDate)}</PermanentOfferStartYear><Item5>年</Item5>
                      <PermanentOfferStartMonth>{getMonth(permanentOfferStartDate)}</PermanentOfferStartMonth><Item5>月</Item5>
                      <PermanentOfferStartDay>{getDay(permanentOfferStartDate)}</PermanentOfferStartDay><Item5>日</Item5>
                    <Item5>
                      ）から、無期雇用契約での雇用に転換することができる。この場合の本契約からの労働条件の変更の有無（
                    </Item5>
                    <ConversionToPermanenOffer>{conversionToPermanentOfferText}</ConversionToPermanenOffer>
                    {
                        conversionToPermanentOffer && <span>（別紙２のとおり）</span>
                    }
                    <Item5>）</Item5>
                  </span>
                  <br/>
                  <span>{tFixed("exceeds_five_year_fixed_term")}</span>
                  <br/>
                  <span>
                    <Trans
                      i18nKey={getTKey("conversion_to_permanent_offer")}
                      tOptions={tOptions}
                      values={{
                        presence: conversionToPermanentOfferTextTrans,
                        year: getYear(permanentOfferStartDate),
                        month: getMonth(permanentOfferStartDate),
                        day: getDay(permanentOfferStartDate),
                        attachment2: conversionToPermanentOffer ? tFixed("attachment_2") : ""
                      }}
                      components={{
                        seg: <Item5/>,
                        presence: <ConversionToPermanenOffer/>,
                        year: <PermanentOfferStartYear/>,
                        month: <PermanentOfferStartMonth/>,
                        day: <PermanentOfferStartDay/>
                      }}
                    />
                  </span>
                </Segment>
              </ListBlockContent>
            </ListBlock>
          )}
        </ListBlocksContainer>
      </Indent>
    </Section>
  );
};

export default ContractPeriodSection;
